import React, { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import "../Recibos/Recibos.css"
import Header from "../../../components/Header/Header";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileImport, faArrowLeft, faRefresh } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import { toast } from 'react-hot-toast';
import ModalMessage from "../../../components/ModalMessage";
import { v4 as uuidv4 } from 'uuid';
import ProgressBarModal from "../../../components/progressBar";
import cookieManager from "../../../components/CookieManager/CookieManager";


function Recibos() {

    const navigate = useNavigate();

    const [errorModalIsOpen, setErrorModalIsOpen] = useState(false);
    const [message, setMessage] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingRefresh, setIsLoadingRefresh] = useState(false);
    const [nome_empresa, setNomeEmpresa] = useState('Hospital de Amor');
    const [progress, setProgress] = useState(0);
    const [showProgressBar, setShowProgressBar] = useState(false);
    const [logs, setLogs] = useState([]);

    var userData;
    try {

        userData = JSON.parse(atob(cookieManager.getCookie("data")));

    } catch (e) { }

    const token = process.env.REACT_APP_DP_BEARER_TOKEN
    const apiUrl = process.env.REACT_APP_API_QR_DOMAIN
    const tokenTi = process.env.REACT_APP_API_TI


    function convertToISO88591(content) {

        let buffer = [];

        for (let i = 0; i < content.length; i++) {

            let code = content.charCodeAt(i);

            if (code <= 0xFF) {

                buffer.push(code);

            } else {

                buffer.push(0x3F); // Substitui caracteres não suportados por '?'

            }

        }

        return new Uint8Array(buffer);

    }

    function deslogar() {

        cookieManager.clearCookies();
        navigate("/");

    }


    function formatarData(dataString) {

        const data = new Date(dataString);
        const dia = data.getDate().toString().padStart(2, '0');
        const mes = (data.getMonth() + 1).toString().padStart(2, '0');
        const ano = data.getFullYear();
        const hora = data.getHours().toString().padStart(2, '0');
        const minutos = data.getMinutes().toString().padStart(2, '0');
        const segundos = data.getSeconds().toString().padStart(2, '0');

        return `${dia}/${mes}/${ano} ${hora}:${minutos}:${segundos}`;

    }


    useEffect(() => {

        historicoEnvio();

    }, []);


    function formataCNPJ(cnpj) {

        const cnpjArquivo = cnpj.replace(/\D/g, '');
        const digitos = cnpjArquivo.slice(-14);
        const cnpjFormatado = digitos.slice(0, 2) + '.' + digitos.slice(2, 5) + '.' + digitos.slice(5, 8) + '/' + digitos.slice(8, 12) + '-' + digitos.slice(12, 14);

        return cnpjFormatado;

    }


    async function historicoEnvio() {

        if (!cookieManager.verifySessionCookie("session")) {

            cookieManager.clearCookies();
            navigate("/");

        }

        if (!showProgressBar) {

            setIsLoadingRefresh(true);

        }

        try {

            const dia = new Date();
            const yyyymmdd = dia.toISOString().slice(0, 10);

            const config = {
                method: 'post',
                url: `${apiUrl}/logs`,
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${tokenTi}`
                },
                data: {
                    "cracha": parseInt(userData["cracha"]),
                    "nome_empresa": "Hospital de Amor",
                    "tipo": "Envio Holerites",
                    "data": yyyymmdd,
                    "quantidade": 1000
                },
                withCredentials: true
            };

            const retorno = await axios(config);
            const response = JSON.parse(retorno.data.data);

            if (!response) {

                return;

            }

            const sortedLogs = response.sort((a, b) => new Date(b.data_envio) - new Date(a.data_envio));

            const totalRegistrosPorUuid = {};

            sortedLogs.forEach(dado => {

                const { uuid_envio, ...resto } = dado;

                if (totalRegistrosPorUuid[uuid_envio]) {

                    totalRegistrosPorUuid[uuid_envio].quantidade_registros += dado.quantidade_registros;

                } else {

                    totalRegistrosPorUuid[uuid_envio] = { ...resto, quantidade_registros: dado.quantidade_registros };

                }

            });

            // Converte o objeto de agrupamento em um array
            const totalRegistrosArray = Object.values(totalRegistrosPorUuid);

            setLogs(totalRegistrosArray);

        } catch (error) {

            // console.error('Erro ao processar o arquivo:', error);

        } finally {

            setIsLoadingRefresh(false);

        }

    }


    async function processFile() {

        const uuidEnvio = uuidv4()
        const inputElement = document.getElementById("fileInput");

        if (!inputElement.files.length) {

            toast.dismiss();
            toast.error("Selecione um arquivo!");

            return;

        }

        setIsLoading(true);
        setErrorModalIsOpen(true);

        const file = inputElement.files[0];

        try {

            const fileUrl = URL.createObjectURL(file);
            const response = await fetch(fileUrl);
            const content = await response.text();
            URL.revokeObjectURL(fileUrl);

            const lines = content.split('\r\n');
            const firstLine = lines[0];

            let group = [];
            let groupCount = 0;
            let currentSize = 0;
            let recordCount = 0;
            const maxSize = 1024 * 1024; // 1MB
            let i = 1;
            let holeriteColaborador

            setShowProgressBar(true);

            while (i < lines.length) {

                holeriteColaborador = '';

                if (lines[i].startsWith('1I0')) {   // Colaborador

                    holeriteColaborador = lines[i] + '\r\n';
                    i++;

                    while (i < lines.length && !lines[i].startsWith('1I0')) { //enquanto não é ultima linha do colaborador

                        holeriteColaborador += lines[i] + '\r\n';
                        i++;

                    }

                    recordCount++;
                    const holeriteColaboradorSize = new Blob([holeriteColaborador]).size;

                    if (currentSize + holeriteColaboradorSize <= maxSize) {

                        group.push(holeriteColaborador.trimEnd('\r\n'));
                        currentSize += holeriteColaboradorSize;

                    } else {

                        groupCount++;
                        await enviarArquivo(firstLine + '\r\n' + group.join('\r\n'), uuidEnvio);
                        group = [holeriteColaborador.trimEnd('\r\n')];
                        currentSize = 0;

                    }

                } else {

                    i++;

                }

                const progresso = Math.round((i / lines.length) * 99);

                setProgress(progresso);

            }

            if (group.length > 0) {

                groupCount++;
                await enviarArquivo(firstLine + '\r\n' + group.join('\r\n'), uuidEnvio);

            }

            setTimeout(function () {

                historicoEnvio();

            }, 2_000);

            toast.dismiss();
            toast.success('Arquivo enviado com sucesso!');

        } catch (error) {

            alert('Erro ao enviar arquivo');

        } finally {

            setIsLoading(false);
            setErrorModalIsOpen(false);
            setShowProgressBar(false);

        }

    }


    async function updateLogs() {

        historicoEnvio();

    }


    const enviarArquivo = async (arquivo, uuid) => {

        if (!cookieManager.verifySessionCookie("session")) {

            cookieManager.clearCookies();
            navigate("/");

        }

        try {

            const encodedContent = convertToISO88591(arquivo);
            const file = new Blob([encodedContent], { type: 'text/plain' });

            const formData = new FormData();
            formData.append("file", file);
            formData.append("cracha", userData && "cracha" in userData ? userData["cracha"] : "");
            formData.append("nome_empresa", nome_empresa);
            const config = {
                method: "post",
                url: `${apiUrl}/holerites/upload`,
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'uuid': uuid
                },
                data: formData,
                withCredentials: true
            };

            const response = await axios(config);
            const retorno = response.data;

            if ("code" in retorno && retorno.status === false) {

                switch (retorno.code) {

                    case 5060:
                        toast.dismiss();
                        toast.error('Arquvivo inválido!');

                        return;

                    case 5061:
                        toast.dismiss();
                        toast.error('Arquivo maior que 10MB!');

                        return;

                    case 5063:
                    case 5064:
                        deslogar();

                        return;

                }

            } else if ("code" in retorno && retorno.status === true) {

                document.getElementById("fileInput").value = "";

            }

            if (response.message === "Service Unavailable") {

                toast.dismiss();
                toast.error('Serviço indisponível');

            }

        } catch (error) {

            toast.dismiss();
            toast.error('Erro ao enviar arquivo');

        }

        await new Promise(resolve => setTimeout(resolve, 5000)); // espera 5 segundos

    };


    const FileChange = async (event) => {

        setShowProgressBar(false);
        setProgress(0);

        const file = event.target.files[0];

        if (file) {

            const fileExtension = file.name.split('.').pop().toLowerCase();
            const fileType = file.type;

            toast.dismiss();

            if (file.size > 10000000) {

                toast.error("O arquivo deve ter no máximo 10MB!");
                document.getElementById("fileInput").value = "";

                return;

            } else if (fileExtension !== 'txt' || fileType !== 'text/plain') {

                toast.error('Por favor, selecione um arquivo .txt válido.');
                document.getElementById("fileInput").value = "";

                return;

            }

        }

        try {

            const fileUrl = URL.createObjectURL(file);
            const response = await fetch(fileUrl);
            const content = await response.text();
            URL.revokeObjectURL(fileUrl);
            const lines = content.split('\n');
            const linhaColab = lines.filter(line => line.startsWith('1I0'));
            const totalColaboradores = linhaColab.length;

            const firstLine = lines[0];
            const cnpjArquivo = firstLine.slice(40, 54);

            const cnpjFormatado = formataCNPJ(cnpjArquivo);

            const message = `
            <div style="width: 500px">
                <div style="text-align: center">
                        <h1>Atenção!</h1>
                        <p>Verifique os dados, antes de enviar o arquivo.</p>
                    <div style="padding: 10px; margin-top: 10px; background-color: #FAF9F9; border-radius: 10px">
                        <p style="margin: 5px 0; font-size: 16px; color: #3A3C40;">
                            <strong>${totalColaboradores}</strong> Colaboradores encontrados
                        </p>
                        <p style="margin: 5px 0; font-size: 16px; color: #3A3C40;">
                            <strong>CNPJ:</strong> ${cnpjFormatado}
                        </p>
                    </div>
                </div>
            </div>
`;

            setIsLoading(false);
            setMessage(message);
            setErrorModalIsOpen(true);

        }
        catch (error) {

            toast.dismiss();
            toast.error('Erro ao carregar arquivo');

        }

    };


    return (
        <>

            <Header />

            <div className="container-recibos">

                <div className="container-recibos-content">

                    <div className="container-recibos-titulo">
                        <h1>Envio dos Holerites</h1>
                        <p>Função para enviar holerites das filiais</p>
                    </div>

                    <input id="fileInput" type="file" onChange={FileChange} />

                    <div className="container-recibo-botao">
                        <button className="voltar" title="Voltar" onClick={() => navigate("/CardDepartamentoPessoal")}>
                            <FontAwesomeIcon className="icone" icon={faArrowLeft} />Voltar</button>
                        <button className="primary" onClick={processFile}>
                            <FontAwesomeIcon className="icone" icon={faFileImport} />Enviar</button>
                    </div>

                    <ModalMessage
                        isOpen={errorModalIsOpen}
                        errorMessage={<div dangerouslySetInnerHTML={{ __html: message }} />}
                        onRequestClose={() => setErrorModalIsOpen(false)}
                        isLoading={isLoading}
                        buttonText="OK"
                    />

                    <ProgressBarModal
                        isOpen={showProgressBar}
                        progress={progress}
                        onRequestClose={() => setShowProgressBar(false)}
                    />
                </div>

                <div className="container-logs-content">
                    {/* <!-- Logs --> */}

                    <div className="log-table-header">

                        <div className="log-table-title">
                            <h3>Últimos Registros de Envio</h3>
                        </div>

                        <div className="log-table-refresh">
                            <button className="primary" onClick={updateLogs}><FontAwesomeIcon className="icone" icon={faRefresh} />Atualizar</button>
                        </div>

                    </div>

                    <div className="logs-container" style={{ overflowX: 'auto' }}>

                        <table className="log-table">

                            <thead>
                                <tr>
                                    <th>CNPJ</th>
                                    <th>Usuário Envio</th>
                                    <th>Data Envio</th>
                                    <th>Data Movimento</th>
                                    <th>Quantidade Registros</th>
                                </tr>
                            </thead>

                            <tbody>
                                {logs.map((log) => (
                                    <tr key={log.uuid}>
                                        <td>{formataCNPJ(log.cnpj)}</td>
                                        <td>{log.cracha}</td>
                                        <td>{formatarData(log.data)}</td>
                                        <td>{log.data_movimento}</td>
                                        <td>{log.quantidade_registros}</td>
                                    </tr>
                                ))}
                            </tbody>

                        </table>

                    </div>

                </div>

                <ModalMessage isLoading={isLoadingRefresh} isOpen={isLoadingRefresh} />

            </div>

        </>
    );
}

export default Recibos;
