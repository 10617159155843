
import { useEffect } from 'react';
import Rotas from './Routes';
import Toast from './components/Notifications/Toast';
import { DataProvider } from './datacontext';
import { useLocation, useNavigate } from 'react-router-dom';
import cookieManager from './components/CookieManager/CookieManager';


function App() {

  const navigate = useNavigate();
  const location = useLocation();


  useEffect(() => {

    cookieManager.setCookie("lastPath", location.pathname);

  }, [location]);


  useEffect(() => {

    const lastPath = atob(cookieManager.getCookie("lastPath")) || "/";
    navigate(lastPath);

  }, [navigate]);


  return (
    <DataProvider>
      <Toast />
      <Rotas />
    </DataProvider>

  );

}


export default App;
