import React from "react";
import { useNavigate } from "react-router-dom";
import "../Home/Home.css";
import Header from "../../components/Header/Header"
import cookieManager from "../../components/CookieManager/CookieManager";


// Componente para montar departamentos
const ModeloCard = ({ title, description, onClick }) => {
  return (
    <article className="card-departamento" onClick={onClick}>
      <div className="container-titulo-departamento">
        <h2 className="titulo-departamento">{title}</h2>
        <p>{description}</p>
        <button className="botao-card">Acessar</button>
      </div>
    </article>
  )
}


function Home() {

  const navigate = useNavigate();


  const DepartamentoPessoal = () => {

    navigate("/CardDepartamentoPessoal");

  };


  const PainelTi = () => {

    navigate("/PainelTi");

  };


  // Quando adicionar novos verificar permissão
  const userData = JSON.parse(atob(cookieManager.getCookie("data")));  
  const existeDepartamentoPessoal = userData && "permissoes" in userData && userData["permissoes"].indexOf("departamentoPessoal") !== -1;
  const existeTi = userData && "permissoes" in userData && userData["permissoes"].indexOf("ti") !== -1;

  // Dados dinâmicos para o card
  const departamentoPessoalData = {
    title: "Departamento Pessoal",
    description: "Aqui você pode gerar QR Codes e enviar holerites para colaboradores"
  };

  const PortalTi = {
    title: "Portal TI",
    description: "Gerenciamento Portal e Aplicativo"
  };

  return (
    <>
      <Header />

      <main>

        <div className="container-card-departamento">
          {
            existeDepartamentoPessoal &&
            (
              <ModeloCard title={departamentoPessoalData.title} description={departamentoPessoalData.description} onClick={DepartamentoPessoal} />
            )
          }
          {
            existeTi &&
            (
              <ModeloCard title={PortalTi.title} description={PortalTi.description} onClick={PainelTi} />
            )
          }
        </div>

      </main>

    </>

  );

}


export default Home;