import React from 'react';
import ReactModal from 'react-modal';
import ProgressBar from "@ramonak/react-progress-bar";


const ProgressBarModal = ({ isOpen, onRequestClose, progress }) => {


    return (
        <ReactModal
            isOpen={isOpen}
            onRequestClose={onRequestClose}
            shouldCloseOnOverlayClick={false}
            ariaHideApp={false}
            style={{
                content: {
                    width: '30%',
                    top: '50%',
                    left: '50%',
                    bottom: 'auto',
                    transform: 'translate(-50%, -50%)',
                    borderRadius: '10px',
                    padding: '20px',
                    backgroundColor: '#fff',
                },
                overlay: {
                    backgroundColor: 'rgba(0, 0, 0, 0.8)'
                }
            }}
        >
            <h2>Enviando...</h2>
            <p>Por favor aguarde</p>
            <ProgressBar
                completed={progress}
                bgColor="linear-gradient(to right, #0064C6, #FF0250)"
                width="100%"
                labelAlignment="center"
                labelColor="#ffffff"
                transitionTimingFunction="ease-out"
                animateOnRender
                maxCompleted={100}
            />
        </ReactModal>
    );
};

export default ProgressBarModal