import React, { useState } from "react";
import Header from "../../components/Header/Header";
import ReactModal from "react-modal";
import "./PainelTi.css";
import axios from "axios";
import { toast } from "react-hot-toast";
import { Panico, Iniciar } from "./BotaoPanico";
import { useNavigate } from 'react-router-dom';
import cookieManager from "../../components/CookieManager/CookieManager";
import ModalMessage from "../../components/ModalMessage";


function PainelTi() {

    const navigate = useNavigate();

    const [ModalIsOpen, setModalIsOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const apiUrl = process.env.REACT_APP_API_QR_DOMAIN;
    const token = process.env.REACT_APP_API_TI;

    var userData;
    try {

        userData = JSON.parse(atob(cookieManager.getCookie("data")));

    } catch (e) { }


    async function PararIniciar(info) {

        setModalIsOpen(false);
        setIsLoading(true);

        try {

            if (!cookieManager.verifySessionCookie("session")) {

                cookieManager.clearCookies();
                navigate("/");

            }

            const config = {
                method: "post",
                url: `${apiUrl}/alterar-status`,
                headers: {
                    "Authorization": `Bearer ${token}`,
                    "Content-Type": "application/json"
                },
                data: {
                    "cracha": parseInt(userData["cracha"]),
                    "nome_empresa": "Hospital de Amor",
                    "status": info
                },
                withCredentials: true
            };

            const response = await axios(config);
            const retorno = response.data;

            toast.dismiss();

            if (retorno && retorno.status === true && info === false) {

                toast.success("Aplicativo parado com sucesso");

            } else if (retorno && retorno.status === true && info === true) {

                toast.success("Aplicativo iniciado com sucesso");

            } else {

                toast.error("Erro ao alterar status");

            }

        } catch (error) {

            toast.dismiss();
            toast.error("Sistema indisponível, tente novamente mais tarde");

        } finally {

            setIsLoading(false);

        }


    }


    return (

        <>

            <Header />

            <div className="container-cadastro">
                <div className="container-panico-titulo">
                    <h1>Gerenciamento do Aplicativo</h1>
                </div>
                <div className="container-botoes-panico">
                    <Iniciar onClick={() => PararIniciar(true)} />
                    <Panico onClick={() => setModalIsOpen(true)} />
                </div>
            </div>

            <div>
                <ReactModal
                    isOpen={ModalIsOpen}
                    onRequestClose={() => setModalIsOpen(false)}
                    style={{
                        content: {
                            top: "50%",
                            left: "50%",
                            right: "auto",
                            bottom: "auto",
                            marginRight: "-50%",
                            transform: "translate(-50%, -50%)",
                            borderRadius: "10px",
                            boxShadow: "0 2px 10px rgba(0, 0, 0, 0.2)",
                            padding: "20px"
                        },
                        overlay: {
                            backgroundColor: "rgba(0, 0, 0, 0.7)"
                        }
                    }}
                >

                    <div style={{ padding: "40px" }}>
                        <h2>Você tem certeza que deseja parar o Aplicativo?</h2>
                        <div className="container-botoes-sim-nao">
                            <button onClick={() => PararIniciar(false)} className="confirmar">Sim</button>
                            <button onClick={() => setModalIsOpen(false)} className="nao-confirmar">Não</button>
                        </div>
                    </div>

                </ReactModal>

            </div>

            <ModalMessage isLoading={isLoading} isOpen={isLoading} />

        </>

    );

}


export default PainelTi;