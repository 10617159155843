import React, { useEffect, useState } from 'react';
import './App.css';
import { Route, Routes, Navigate, useNavigate, useLocation } from "react-router-dom";
import Login from './components/Login/Login';
import Recibos from '../src/pages/DepartamentoPessoal/Recibos/Recibos';
import Home from './pages/Home/Home';
import CadastroFuncionario from './pages/DepartamentoPessoal/CadastroFuncionario/CadastroFuncionario';
import { CardDepartamentoPessoal } from './components/Card/Card';
import PainelTi from './pages/Ti/PainelTi';
import cookieManager from './components/CookieManager/CookieManager';


function Rotas() {

  const [autenticado, setAutenticado] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();


  const isLoging = (value) => {

    setAutenticado(value && cookieManager.verifySessionCookie("session"));

  }


  useEffect(() => {

    setAutenticado(cookieManager.verifySessionCookie("session"));

  }, []);


  useEffect(() => {

    if (location.pathname == "/") {

      if (cookieManager.verifySessionCookie("session")) {

        navigate("/home");

      }

    } else if (!cookieManager.verifySessionCookie("session")) {

      cookieManager.clearCookies();
      navigate("/");

    }

  }, [location]);


  return (

    <Routes>

      <Route path="/" element={<Login logar={isLoging} />} />

      {/* rotas protegidas */}
      <Route path="/home" element={autenticado ? <Home /> : <Navigate to="/" />} />
      <Route path="/recibos" element={autenticado ? <Recibos /> : <Navigate to="/" />} />
      <Route path="/CadastroFuncionario" element={autenticado ? <CadastroFuncionario /> : <Navigate to="/" />} />
      <Route path='/CardDepartamentoPessoal' element={autenticado ? <CardDepartamentoPessoal /> : <Navigate to="/" />} />
      <Route path='/PainelTi' element={autenticado ? <PainelTi /> : <Navigate to="/" />} />

    </Routes>

  );

}


export default Rotas;
