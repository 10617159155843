import React from "react";
import "../Card/Card.css";
import { useNavigate } from 'react-router-dom';
import Header from "../Header/Header";
import backCadastro from "../../img/cadastro.svg";
import backHolerite from "../../img/envio-holerite.svg";
import cookieManager from "../CookieManager/CookieManager";


// Componente para montar o card global
const Card = ({ title, description, onClick, img }) => {
    return (
        <article className="card" onClick={onClick}>
            <img src={img} alt="Imagem de fundo" />
            <div className="container-titulo-card">
                <h2 className="titulo-card">{title}</h2>
                <p>{description}</p>
                <button className="botao-card">Acessar</button>
            </div>
        </article>
    )
}

// Componente pai que renderiza o card dinâmico
export const CardDepartamentoPessoal = () => {
    const navigate = useNavigate();

    // Função para lidar com o clique no card
    const CardHolerite = () => {
        navigate("/Recibos");
    };

    const CardCadastro = () => {
        navigate("/CadastroFuncionario");
    };

    // Dados dinâmicos para o card
    const holerite = {
        title: 'Envio Holerites',
        content: 'Conteúdo do Card',
        description: 'Enviar holerites para colaboradores',
        img: backHolerite,
    };
    const cadastro = {
        title: 'Cadastro Colaborador',
        content: 'Conteúdo do Card',
        description: 'Gerar QR Code para enviar para o colaborador',
        img: backCadastro,
    };

    return (
        <>
            <Header />
            <main>
                <div className="container-card">
                    <Card title={holerite.title} description={holerite.description} img={holerite.img} content={holerite.content} onClick={CardHolerite} />
                    <Card title={cadastro.title} description={cadastro.description} img={cadastro.img} content={cadastro.content} onClick={CardCadastro} />
                </div>
            </main>
        </>
    );
};
