import React, { useState } from "react";
import { useNavigate } from 'react-router-dom';
import Header from "../../../components/Header/Header";
import QRCode from "react-qr-code";
import ReactModal from "react-modal";
import '../CadastroFuncionario/CadastroFuncionario.css'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQrcode, faArrowLeft, faX, faPrint, faSpinner } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import { toast } from 'react-hot-toast';
import cookieManager from "../../../components/CookieManager/CookieManager";
import ModalMessage from "../../../components/ModalMessage";


function CadastroFuncionario() {

    const [qrRetorno, setQrRetorno] = useState('');
    const [ModalIsOpen, setModalIsOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [crachaQR, setCrachaQR] = useState('');

    const urlPlataformas = process.env.REACT_APP_LINK_PLATAFORMAS;
    const apiUrl = process.env.REACT_APP_API_QR_DOMAIN;
    const token = process.env.REACT_APP_DP_BEARER_TOKEN;

    var userData;
    try {

        userData = JSON.parse(atob(cookieManager.getCookie("data")));

    } catch (e) { }


    const InputChange = (event) => {

        const inputValue = event.target.value;
        const numericValue = inputValue.replace(/\D/g, '');
        event.target.value = numericValue;
        setCrachaQR(parseInt(numericValue));

    };


    async function solicitarQRCode() {

        if (!cookieManager.verifySessionCookie("session")) {

            cookieManager.clearCookies();
            navigate("/");

        }

        if (!crachaQR) {

            toast.dismiss();
            toast.error("Preencha o campo com o crachá!");
            return;

        }

        setIsLoading(true);
        const empresaQR = "Hospital de Amor";

        try {

            const config = {
                method: "post",
                url: `${apiUrl}/cadastro/qrcode`,
                headers: {
                    "Authorization": `Bearer ${token}`,
                    "Content-Type": "application/json"
                },
                data: {
                    "cracha": parseInt(userData["cracha"]),
                    "nome_empresa": userData["nome_empresa"],
                    "cracha_qr": parseInt(crachaQR),
                    "nome_empresa_qr": empresaQR
                },
                withCredentials: true
            };

            const response = await axios(config);

            const retorno = response.data;

            if (retorno.status === true) {

                toast.dismiss();
                toast.success('QR Code gerado com sucesso');
                setQrRetorno(retorno.qrcode);
                setModalIsOpen(true);

            } else if (retorno.code === 5022) {

                toast.dismiss();
                toast.error('Crachá inválido');

            } else if (retorno.message === "Service Unavailable") {

                toast.dismiss();
                toast.error('Serviço indisponível');

            } else {

                navigate("/")
                cookieManager.clearCookies();

            }

        } catch (error) {

            toast.dismiss();
            toast.error('Sistema indisponível, tente novamente mais tarde');

        } finally {

            setIsLoading(false);

        }

    }

    function Imprimir() {

        toast.dismiss();

        setTimeout(function () {

            window.print();

        }, 200);

    }

    const navigate = useNavigate();

    return (
        <>
            <Header />

            <div className="container-cadastro">

                <div className="container-titulo-cadastro">
                    <h1>Preencha o campo</h1>
                    <p>Informe o número do crachá para gerar o QR Code</p>
                </div>

                <input maxLength={10} type='number' id='cracha' onInput={InputChange} onKeyDown={(e) => {
                    if (e.key === "Enter")
                        solicitarQRCode();
                }} />

                <div className="container-botao-qr">
                    <button className="voltar" title="Voltar" onClick={() => navigate("/CardDepartamentoPessoal")}>
                        <FontAwesomeIcon className="icone" icon={faArrowLeft} />Voltar</button>
                    <button className="primary" onClick={solicitarQRCode}>
                        {isLoading ? (
                            <FontAwesomeIcon className="icone" icon={faSpinner} spin />
                        ) : (
                            <FontAwesomeIcon className="icone" icon={faQrcode} />
                        )}
                        Gerar QR Code
                    </button>
                </div>
            </div>

            <div>

                <ReactModal
                    isOpen={ModalIsOpen}
                    onRequestClose={() => setModalIsOpen(false)}
                    ariaHideApp={false}
                    style={{
                        content: {
                            top: '50%',
                            left: '50%',
                            right: 'auto',
                            bottom: 'auto',
                            marginRight: '-50%',
                            transform: 'translate(-50%, -50%)',
                            borderRadius: '10px',
                            boxShadow: '0 2px 10px rgba(0, 0, 0, 0.2)',
                            padding: '20px',
                            width: 700
                        },
                        overlay: {
                            backgroundColor: 'rgba(0, 0, 0, 0.7)'
                        }
                    }}
                >

                    <div className="container-modal">

                        <section className="container-info">
                            <div>
                                <h1>Instrução para criar conta</h1>
                            </div>

                            <div className="instrucao">

                                <p><strong>1º </strong>Abra a <strong>Play Store</strong> ou <strong>Apple Store</strong> e faça o download do aplicativo<strong> HA Colaborador</strong>.</p>
                                <p><strong>2º </strong>Acesse o aplicativo e vá em<strong> Criar conta</strong>.</p>
                                <p><strong>3º </strong>Aceite as permissões para ter acesso à<strong> câmera</strong>, aponte a câmera para o <strong>QR Code</strong>.</p>
                                <p><strong>4º </strong>Preencha as informações com os seus dados.</p>
                                <p><strong>5º </strong>Volte ao início e entre com a sua <strong>conta</strong>.</p>
                                <p><strong>QR Code</strong> válido por <strong>24 horas</strong>.</p>

                                <section className="container-qr-platform">
                                    <h4>Download</h4>
                                    <QRCode
                                        size={256}
                                        style={{ height: "auto", maxWidth: 100, width: "100%" }}
                                        value={urlPlataformas}
                                        viewBox={`0 0 256 256`}
                                    />
                                    <div>
                                        <p>bit.ly/hacolaborador</p>
                                    </div>
                                </section>

                            </div>

                            <div className="botao-modal">

                                <button className="voltar" onClick={() => setModalIsOpen(false)}>
                                    <FontAwesomeIcon className="icone" icon={faX} />Fechar</button>
                                <button className="primary" onClick={Imprimir}>
                                    <FontAwesomeIcon className="icone" icon={faPrint} />Imprimir</button>
                            </div>

                        </section>

                        <section>
                            <h2 className="cracha">{crachaQR}</h2>
                            <h4 className="cracha">Cadastro</h4>
                            <QRCode className="qrcode-area"
                                size={256}
                                style={{ height: "auto", maxWidth: 200, width: "100%" }}
                                value={qrRetorno}
                                viewBox={`0 0 256 256`}
                            />
                        </section>

                    </div>

                </ReactModal>

            </div>

            <ModalMessage isLoading={isLoading} isOpen={isLoading} />

        </>

    );

}


export default CadastroFuncionario;
