import React from 'react';
import styled, { keyframes } from 'styled-components';

// Define a animação
const bounce = keyframes`
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-5px);
  }
  100% {
    transform: translateY(0);
  }
`;

// Estiliza o botão e aplica a animação ao clicar
const StopButton = styled.button`

 border: none;
  border-radius: 10px;
  padding: 10px 16px;
  margin-right: 10px;
  font-size: 13px;
  font-weight: 500;
  cursor: pointer;
  background-color: var(--rosa-ha-500-oficial);
  color: var(--branco);
  transition: 0.3s ease-in-out;

  &:active {
    animation: ${bounce} 0.3s;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.2), inset 0px 0px 5px rgba(255, 255, 255, 0.3);
  }

  &:before {
    background: rgba(255, 255, 255, 0.3);
    box-shadow: 0 0 10px rgba(255, 255, 255, 0.5);
  }
`;

const StartButton = styled.button`
  
  border: none;
  border-radius: 10px;
  padding: 10px 16px;
  margin-right: 10px;
  font-size: 13px;
  font-weight: 500;
  cursor: pointer;
  background-color: var(--azul-ha-500-oficial);
  color: var(--branco);
  transition: 0.3s ease-in-out;

  &:active {
    animation: ${bounce} 0.3s;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.2), inset 0px 0px 5px rgba(255, 255, 255, 0.3);
  }

  &:before {
    content: '';
    position: absolute;
    top: 10px;
    left: 10px;
    width: 80px;
    height: 80px;
    border-radius: 50%;
    background: rgba(255, 255, 255, 0.3);
    box-shadow: 0 0 10px rgba(255, 255, 255, 0.5);
  }
`;

// Componente do botão Parar
export const Panico = ({ onClick }) => {
  return (
    <StopButton onClick={onClick}>
      Parar
    </StopButton>
  );
};


export const Iniciar = ({ onClick }) => {
  return (
    <StartButton onClick={onClick}>
      Iniciar
    </StartButton>
  );
};





