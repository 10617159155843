import React from 'react';
import { Toaster } from 'react-hot-toast';

function Toast() {
    return (
        <Toaster
            position="top-center"
            reverseOrder={false}
            gutter={8}
            toastOptions={{
                duration: 4000,
                style: {
                    background: '#fff',
                    color: '#000',
                },
            }
            }
        />
    );
}

export default Toast;