import React from 'react';
import ReactModal from 'react-modal';

const ModalMessage = ({ isOpen, onRequestClose, errorMessage, title, isLoading, buttonText }) => {
  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      shouldCloseOnOverlayClick={false}
      ariaHideApp={false}
      style={{
        content: {
          top: '50%',
          left: '50%',
          right: 'auto',
          bottom: 'auto',
          marginRight: '-50%',
          transform: 'translate(-50%, -50%)',
          borderRadius: '10px',
          boxShadow: '0 2px 10px rgba(0, 0, 0, 0.2)',
          padding: '20px'
        },
        overlay: {
          backgroundColor: 'rgba(0, 0, 0, 0.5)'
        }
      }}
    >
      {isLoading ? (
        <p>Carregando...</p>
      ) : (
        <>
          {title && <h2 style={{ marginBottom: '10px' }}>{title}</h2>}
          <div>{errorMessage}</div>
          <button
            onClick={onRequestClose}
            style={{
              marginTop: '10px',
              padding: '8px 16px',
              borderRadius: '5px',
              backgroundColor: '#007bff',
              color: '#fff',
              border: 'none',
              cursor: 'pointer',
              float: 'right'
            }}
          >
            {buttonText || 'Fechar'}
          </button>
        </>
      )}
    </ReactModal>
  );
};

export default ModalMessage;