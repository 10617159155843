import Cookies from "js-cookie";


const CookieManager = {

    setCookie(name, value, hours) {

        const expirationDate = new Date();
        expirationDate.setTime(expirationDate.getTime() + (hours * 60 * 60 * 1_000));
        Cookies.set(name, btoa(value), { expires: expirationDate });

    },


    getCookie(name) {

        return Cookies.get(name);

    },


    removeCookie(name, options = {}) {

        Cookies.remove(name, options);

    },


    clearCookies() {

        for (let cookie in Cookies.get()) {

            Cookies.remove(cookie);

        }

    },


    verifySessionCookie(name) {

        try {

            return /^[\da-f]{8}-[\da-f]{4}-[\da-f]{4}-[\da-f]{4}-[\da-f]{12}$/i.test(atob(this.getCookie(name)));

        } catch (e) {

            return false;

        }

    }

}


export default CookieManager;