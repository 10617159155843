import React, { createContext, useState, useEffect } from 'react';


export const DataContext = createContext();


export const DataProvider = ({ children }) => {

    const [globalData, setGlobalData] = useState(() => {

        //const savedData = localStorage.getItem("globalData");
        //return savedData ? JSON.parse(atob(savedData)) : {};

    });


    useEffect(() => {

        //localStorage.setItem('globalData', btoa(JSON.stringify(globalData)));

    }, [globalData]);


    return (
        <DataContext.Provider value={{ globalData, setGlobalData }}>
            {children}
        </DataContext.Provider>
    );

};