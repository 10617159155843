import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleUser, faPowerOff } from "@fortawesome/free-solid-svg-icons";
import "../Header/Header.css";
import cookieManager from "../CookieManager/CookieManager";
import logoHa from "../../../src/img/logo-ha-horizontal.png";
import axios from "axios";
import ModalMessage from "../ModalMessage";


function Header() {

    const navigate = useNavigate();

    const [isLoading, setLoading] = useState(false);

    const apiUrl = process.env.REACT_APP_API_QR_DOMAIN;
    const token = process.env.REACT_APP_API_TOKEN;

    var userData;
    try {

        userData = JSON.parse(atob(cookieManager.getCookie("data")));

    } catch (e) { }


    async function deslogar() {

        cookieManager.clearCookies();

        try {

            setLoading(true);

            const config = {
                method: "post",
                url: `${apiUrl}/portal/logout`,
                headers: {
                    "Authorization": `Bearer ${token}`,
                    "Content-Type": "application/json"
                },
                data: {
                    "cracha": parseInt(userData["cracha"]),
                    "nome_empresa": "Hospital de Amor"
                },
                withCredentials: true
            };

            await axios(config);

        } catch (err) { }

        finally {

            setLoading(false);

        }

        cookieManager.clearCookies();
        navigate("/");

    }


    function handleLogoClick() {

        navigate("/home");

    }


    return (
        <>
            <header>

                <>
                    <img src={logoHa} className="logo" onClick={handleLogoClick} style={{ cursor: "pointer" }} />
                </>

                <div id="container-logoff">

                    <div className="usuario">
                        <FontAwesomeIcon className="icon-user" icon={faCircleUser} />
                        <p>{userData && "name" in userData ? userData["name"] : ""}</p>
                    </div>

                    <div>
                        <button className="logoff" onClick={deslogar}><FontAwesomeIcon icon={faPowerOff} className="icon" />Sair</button>
                    </div>

                </div>

            </header>

            <ModalMessage isLoading={isLoading} isOpen={isLoading} />

        </>

    );

}


export default Header;
